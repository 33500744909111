import styled from 'styled-components'
import { primaryColor, md, xl, lg } from 'config/variables'

export const AutomatingsecStyles = styled.div`
  margin-bottom: 70px;
  position: relative;
  padding-bottom: 160px;
  ${xl(`
      padding-bottom: 100px;
  `)} 
  ${lg(`
       padding-bottom: 90px;
  `)} 
 ${md(`
      margin-bottom: 40px;
      padding-bottom: 40px;
  `)} 
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 729px;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(252, 253, 255, 0),
      #f7f7f7
    );
    z-index: -1;
  }
  .AutomatingContentRow {
    display: flex;
    justify-content: space-between;
    ${md(`
      flex-direction: column;
    `)} 
    .AutomatingContentLeft {
      flex: 0 1 465px;
      padding-right: 45px;
      ${xl(`
      padding-right: 25px;
      flex: 0 1 459px;
    `)} 
     ${lg(`
        padding-right: 15px;
        flex: 0 1 265px;
     `)} 
      ${md(`
      flex: 1 1 auto;
      padding-right: 0;
    `)} 
      .AutomatingContentTitle {
        padding-top: 35px;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 3px;
          top: 0;
          left: 0;
          z-index: -1;
          background:${primaryColor};
          ${md(`
           width: 200px;
          `)} 
        }
        ${md(`
            padding-top: 20px;
        `)} 
        h2 {
          margin-bottom: 0px;
        }
      }
    }
    .AutomatingDetailsRight {
      flex: 0 1 675px;
      padding-left: 45px;
      ${xl(`
      padding-left: 25px;
      flex: 0 1 484px;
    `)}
      ${lg(`
        flex: 0 1 423px;
        padding-left: 15px;
     `)} 
      ${md(`
      flex: 1 1 auto;
      padding-left: 0;
    `)} 
      .AutomatingDetails {
        padding-top: 35px;
        position: relative;
        ${md(`
           padding-top: 16px;
          `)} 
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 3px;
          top: 0;
          left: 0;
          z-index: -1;
          background:${primaryColor};
          ${md(`
           display:none;
          `)} 
        }
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
  .deploymentAutomationImage {
    figure {
      margin-top: 60px;
      margin-bottom:0px; 
      ${lg(`
            margin-top: 50px;
        `)} 
      ${md(`
            margin-top: 30px;
       `)} 
    }
  }
`
