import React from 'react'
import { CaseintroductionStyles } from './Caseintroduction.styles'
import Image from 'components/ImgOptimized'
function Caseintroduction(props) {
  const {
    caseintrodata: { introducrtionContent },
    showFullimages,
  } = props

  const {
    data: {
      businessFullImage: {
        childImageSharp: { fluid: businessFullImage },
      },
    },
  } = props
  return (
    <CaseintroductionStyles className="caseintro-section">
      <div className="container">
        <h2>{introducrtionContent.maintile}</h2>
        <p>{introducrtionContent.mainpara}</p>
        {introducrtionContent.subheading ||
        introducrtionContent.paraLeft ||
        introducrtionContent.paraRight ? (
          <div className="sub-content">
            <h3>{introducrtionContent.subheading}</h3>
            <div className="row">
              <div className="col">
                <p>{introducrtionContent.paraLeft}</p>
              </div>
              <div className="col">
                <p>{introducrtionContent.paraRight}</p>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {showFullimages && (
          <div className="businessFullImage">
            <figure>
              <Image
                fluid={businessFullImage}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 416,
                }}
              />
            </figure>
          </div>
        )}
      </div>
    </CaseintroductionStyles>
  )
}

export default Caseintroduction
