import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { lg, md, xl } from 'config/variables'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { WayPointProps } from 'components/Props/way-point'
import Casebanner from 'components/Casebanner/Casebanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Caseengagementsec from 'components/Caseengagementsec/Caseengagementsec'
import Caseresultsec from 'components/Caseresultsec/Caseresultsec'
import Eventscreensec from 'components/Eventscreensec/Eventscreensec'
import Customerexperiencesec from 'components/Customerexperiencesec/Customerexperiencesec'
import Automatingsec from 'components/Automatingsec/Automatingsec'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'

export const WayPointPage = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #fbbf73;
          }
        }
      }
    }
  } 
  .Caseresultsec  {
    padding:0px;
    margin:0px 0 54px;
    &:after {
      display:none;
    }
    ${lg(`
        margin-bottom: 30px;
        padding-bottom: 0;
    `)} 
    ${md(`
        margin-bottom: 20px;
        padding-bottom: 0;
    `)} 
  }
  .automatingSec   {
    padding:0px;
    margin:0px 0 126px;
    &:after {
      display:none;
    }
    ${xl(`
      margin-bottom: 100px;
    `)}
    ${lg(`
        margin-bottom: 90px;
    `)}
    ${md(`
        margin-bottom: 40px;
    `)} 
  }
  .architectureSec {
    .customerExperienceSec {
      .CustomerExpMainTitle {
        h2 {
          padding-bottom:0px;
          margin-bottom:40px;
          &::after {
            display:none;
          }
        }
      }
    }
  }
  `

const WayPoint = memo(props => {
  const { data } = props
  const { location } = props

  let item = [
    [
      props.data.eventScreen1.childImageSharp.fluid,
      props.data.eventScreen2.childImageSharp.fluid,
      props.data.eventScreen3.childImageSharp.fluid,
      props.data.eventScreen4.childImageSharp.fluid,
      props.data.eventScreen5.childImageSharp.fluid,
      props.data.eventScreen6.childImageSharp.fluid,
    ],
    [
      props.data.eventScreen7.childImageSharp.fluid,
      props.data.eventScreen8.childImageSharp.fluid,
      props.data.eventScreen9.childImageSharp.fluid,
      props.data.eventScreen10.childImageSharp.fluid,
      props.data.eventScreen11.childImageSharp.fluid,
      props.data.eventScreen12.childImageSharp.fluid,
    ],
  ]

  let cusExpeItem = [props.data.improvingImg.childImageSharp.fluid]
  let architectureItem = [
    props.data.architectureItem.childImageSharp.fluid,
    props.data.socialMedia.childImageSharp.fluid,
  ]

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })
  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="WayPoint"
        description=""
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <WayPointPage>
        <Casebanner
          bannerdata={WayPointProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={WayPointProps}
          {...props}
          showFullimages={true}
        />
        <div className="lazy-load-div">
          <Caseengagementsec
            Caseengagementsecdata={WayPointProps.CaseengagementsecProps}
          />
        </div>
        {showContent && (
          <>
            <Caseresultsec
              caseresultsecdata={WayPointProps}
              {...props}
              showImages={false}
            />
            <Eventscreensec
              eventScrrensecdata={WayPointProps}
              {...props}
              items={item}
            />
            <Customerexperiencesec
              CustomerExperienceProps={WayPointProps.customerExperienceProps}
              {...props}
              fluid={cusExpeItem}
            />
            <Automatingsec Automatingsecdata={WayPointProps} {...props} />
            <div className="architectureSec">
              <Customerexperiencesec
                CustomerExperienceProps={WayPointProps.architectureProps}
                {...props}
                fluid={architectureItem}
              />
            </div>
            <Relatedcaselist relatedcaselistdata={WayPointProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={WayPointProps} />
          </>
        )}
      </WayPointPage>
    </Layout>
  )
})
export default WayPoint

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/wayPointBanner@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen1: file(relativePath: { regex: "/swifts1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen2: file(relativePath: { regex: "/swifts2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen3: file(relativePath: { regex: "/swifts3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen4: file(relativePath: { regex: "/swifts4@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen5: file(relativePath: { regex: "/swifts5@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen6: file(relativePath: { regex: "/swifts6@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen7: file(relativePath: { regex: "/swifts7@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen8: file(relativePath: { regex: "/swifts8@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen9: file(relativePath: { regex: "/swifts9@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseFullimages: file(relativePath: { regex: "/insuranceDetails@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 866) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    eventScreen1: file(
      relativePath: { regex: "/create-the-events-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    eventScreen2: file(
      relativePath: { regex: "/user-artists-events@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    eventScreen3: file(
      relativePath: { regex: "/waypoint-media-events@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    eventScreen4: file(relativePath: { regex: "/line-ups-event@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    eventScreen5: file(relativePath: { regex: "/map-for-event@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    eventScreen6: file(relativePath: { regex: "/chat-groups-events@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    eventScreen7: file(
      relativePath: { regex: "/waypoint-attendees-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    eventScreen8: file(
      relativePath: { regex: "/view-event-line-ups@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    eventScreen9: file(relativePath: { regex: "/track-point-event@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    eventScreen10: file(
      relativePath: { regex: "/track-friends-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    eventScreen11: file(
      relativePath: { regex: "/without-internet-connectivity@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    eventScreen12: file(
      relativePath: { regex: "/sos-message-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    improvingImg: file(
      relativePath: { regex: "/losing-friend-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 545) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    deploymentAutomationImage: file(
      relativePath: { regex: "/offline-messaging-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    architectureItem: file(
      relativePath: { regex: "/architecture-diagram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    socialMedia: file(
      relativePath: { regex: "/social-media-diagram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/fillinrx-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    singlecase2: file(
      relativePath: { regex: "/visibly-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
