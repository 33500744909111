import styled from 'styled-components'
import { xl, md } from 'config/variables'

export const CasecontactcardinfoStyles = styled.div`
  padding: 0 0 41px;
  background: linear-gradient(to bottom, rgba(243, 243, 243, 0), #fbfbf4);
  ${xl(`   
     padding: 0 0 30px;
  `)}
  ${md(`   
     padding: 0 0 1px;
  `)}
`
