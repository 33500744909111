import React from 'react'
import { images } from 'config/images'

export const WayPointProps = {
  bannerProps: {
    heading: 'WayPoint',
    bannerPara:
      'engaging users with a digital platform at live music festivals',
    BannerAlt: 'WayPoint Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        The live concert industry will experience significant growth during the
        next few years, aiming $31 billion by 2022 and 1 billion attendees in a
        single year. No matter how big the number is, one major problem
        persisted in engaging this massive amount of attendees in a
        network-strapped area.
        <br />
        <br /> In 2014, Tomorrowland sat a new standard by introducing a
        ‘friend’-ing device festival bands, to help people stay in touch and
        engage with all their friends during the music festival.
        <br />
        <br /> However, WayPoint wanted to take the engagement technology to the
        new level. It wanted to build, an open platform to organize, manage and
        attend the music festival all the while engaging the users pre, post and
        during the same. It wanted to offer real time messaging with friends
        without any internet connectivity, ability to get directions to a friend
        or any POI using compass with distance to walk, and festival information
        at fingertips including schedule and artist info.
      </>
    ),
  },
  CaseengagementsecProps: {
    title:
      'Optimizing existing code base for better scalability and performance',
    bottomTitle: 'Engagement',
    bottomContent: (
      <>
        From MVP to full
        <br />
        grown product
      </>
    ),
    maincontent: (
      <>
        Client approached us with their product development needs and under
        progress code written by the previous development team. After proper
        assessment, we didn’t find the code healthy enough to proceed with
        further development as it did not follow any standard architecture
        patterns like MVC/MVVM, or support auto layout and storyboarding as per
        iOS coding standards. <br /> <br /> We decided to optimize the code
        first to make it scalable before adding any new functionalities. Later
        we followed monthly sprint approach and agile development method to
        deliver the product in phases.
      </>
    ),
  },
  casereSultsecProps: {
    heading: 'Result',
    RightSubHead: 'A Modern approach to live your events & engage users',
    maincontent: (
      <>
        In 3 months of development cycle, we created a clutter-free digital
        platform for organizer to create events and invite artists and
        attendees. <br />
        <br /> We built an iOS app using Swift and Android app using Kotlin. We
        leveraged native features like bluetooth, compass, GPS, etc; to provide
        a seamless experience to the users. We also developed scalable backend
        that can meet the varying demand throughout the year.
        <br />
        <br /> Waypoint mobile app fulfills the need of event organizer and
        event goers by offering features like:
      </>
    ),
    imgURL2x: images.waypointEesultScreen2x,
    imgURL: images.waypointEesultScreen,
  },
  eventScreensecProps: {
    eventScreenDetails: [
      {
        bottomTitle: 'Organizer',
        eventScreenTitle: [
          {
            eventScreenInfo: 'Create the events',
          },
          {
            eventScreenInfo: 'Add/Invite users or Artists',
          },
          {
            eventScreenInfo: 'Add media for events',
          },
          {
            eventScreenInfo: 'Manage event Line-ups',
          },
          {
            eventScreenInfo: 'Set Point of Interest on map for event',
          },
          {
            eventScreenInfo: 'Create chat groups',
          },
        ],
      },
      {
        bottomTitle: 'Goer',
        eventScreenTitle: [
          {
            eventScreenInfo: 'View events and attendees',
          },
          {
            eventScreenInfo: 'View event media and Line-ups',
          },
          {
            eventScreenInfo: 'Track Point of Interest in an event',
          },
          {
            eventScreenInfo: 'Track friends through compass ',
          },
          {
            eventScreenInfo: 'Chat with friends without internet connectivity',
          },
          {
            eventScreenInfo: 'Send SOS message to friends',
          },
        ],
      },
    ],
  },
  customerExperienceProps: {
    CustomerexperienceDetails: [
      {
        mainTitle: 'No more losing a friend',
        para: (
          <>
            Music festival locations are huge in size, sometimes way bigger than
            Central Park of New York.
            <br /> <br /> Having digital map helps attendees in finding venues
            for parking, eateries, medical aid, washrooms etc. But how would you
            find a friend who is lost and you do not have internet connectivity?
            <br /> <br /> Waypointers uses a ‘built-in compass’ in offline mode
            to track your friend. It can precisely point towards the target
            location along with distance in feet.
            <br /> <br /> It also has the ability to locate any point of
            interest which is already pinned on the map by event organizer. To
            enable this feature, we utilized Distance Matrix API by Google Map
            to calculate the walking distance between 2 users’s coordinates.
          </>
        ),
      },
    ],
  },

  AutomatingsecProps: {
    Title: 'Offline messaging for unbreakable fun',
    para: (
      <>
        Verbal communication with friends is difficult due to loud music and
        crowd noise. Problem gets double fold if attendees are facing network
        issues if they rely on apps that requires internet for communication.{' '}
        <br /> <br /> Considering this problem, our technical team decided to
        follow Bluetooth Mesh Networking approach for peer to peer
        communication. With the use of ‘Bridgefy’ SDK for offline messaging, we
        implemented chat feature to deliver messages via intermediary devices
        connected via Bluetooth. It allows user to send messages up to 600-700 m
        range without any internet connectivity.
      </>
    ),
  },
  architectureProps: {
    CustomerexperienceDetails: [
      {
        mainTitle:
          'Highly scalable backend architecture to handle fluctuating load',
        para: (
          <>
            There are millions of participants in a single event, and billions
            if we take account for all the music festivals happening throughout
            the year. The platform was designed keeping in mind the number of
            attendees so that it can easily handle thousands of concurrent
            requests at the same time. Leveraging auto scalable AWS EC2
            instances, we eliminated manual intervention of increasing or
            decreasing server capacity.
          </>
        ),
        imgCaption: 'Auto scaling server based on fluctuating demand',
      },
      {
        mainTitle:
          'Social Media Integration with Mobile deep linking to increase user engagement',
        para: (
          <>
            Social media can be the most efficient channel to drive traffic to
            the app considering the young target audience. Waypointers allows
            users to create public or private events and invite their friends
            from different social media platforms like Facebook, LinkedIn and
            Twitter, as well their local contact book. We implemented deep
            linking mechanism with Branch.io to redirect invited user to
            specific event screen whether they have app installed on their phone
            or they install it from app store for the first time.
          </>
        ),
        imgCaption: 'Deep linking for great user experience',
      },
    ],
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'FillinRx',
        para: 'a pharmacy staffing solution',
        className: 'mint',
        link: '/fill-in-rx-pharmacy-staffing/',
      },
      {
        title: 'Visibly',
        para: 'get your vision prescription in five minutes',
        className: 'light-lavender',
        link: '/visibly-vision prescription/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
