import styled from 'styled-components'
import { primaryColor, xl, lg, md } from 'config/variables'
export const CustomerexperiencesecStyles = styled.div`
  margin-bottom: 134px;
  ${xl(`
      margin-bottom: 100px;
  `)}
  ${lg(`
      margin-bottom: 90px;
  `)}
  ${md(`
      margin-bottom: 40px;
  `)} 
  .CustomerExpContent {
    padding-bottom: 110px;
    ${xl(`
      padding-bottom: 90px;
    `)}
    ${lg(`
        padding-bottom: 90px;
    `)}
    ${md(`
       padding-bottom: 40px;
    `)}
    &:last-child {
      padding-bottom: 0px;
    }
    &:nth-child(even) {
      .CustomerExpDetails {
        order: 2;
        padding-left: 18px;
        padding-right: 0;
        ${lg(`
          padding-left: 0;
          order: 2;
          padding-bottom:0;
        `)} 

      }
      .CustomerExpImages {
        padding-right: 18px;
        padding-left: 0px;
        ${lg(`
          padding-right: 0;
          padding-bottom:30px;
          order: 1;
        `)} 
        figure {
          margin:0px;
        }
      }
    }
  }
  .CustomerExpMainTitle {
    h2 {
      margin-bottom: 30px;
      padding-bottom: 30px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0px;
        width: 251px;
        height: 3px;
        background: ${primaryColor};
        ${md(`
          width: 90px;
        `)}
      }
    }
  }
  .CustomerExpRow {
    display: flex;
    justify-content: space-between;
    ${lg(`
      flex-direction: column;
    `)}
    .CustomerExpDetails {
      flex: 0 1 50%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      padding-right: 18px;
      ${lg(`
      padding-right: 0;
      flex: 1 1 auto;
      padding-bottom:0;
      order: 2;
      `)}
      
      h2 {
        margin-bottom: 32px;
      }

      p {
        margin: 0px;
      }
    }
    .CustomerExpImages {
      flex: 0 1 50%;
      padding-left: 18px;
      align-items:center;
      display:flex;
      ${lg(`
        padding-left: 0;
        flex: 1 1 auto;
        order: 1;
        padding-bottom:30px;
      `)} 
       figure {
          margin:0px;
        }
       video {
         max-width:100%;
       }
    }
  }
`
