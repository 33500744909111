import React from 'react'
import { CustomerexperiencesecStyles } from './Customerexperiencesec.styles'
import Image from 'components/ImgOptimized'

function Customerexperiencesec(props) {
  const { CustomerExperienceProps } = props

  return (
    <CustomerexperiencesecStyles className="customerExperienceSec">
      <div className="container">
        {CustomerExperienceProps.CustomerexperienceDetails.map(
          (CustomerExperienceProp, i) => {
            return (
              <div key={i} className="CustomerExpContent">
                {CustomerExperienceProp.mainTitle ? (
                  <div className="CustomerExpMainTitle">
                    <h2>{CustomerExperienceProp.mainTitle}</h2>
                  </div>
                ) : (
                  ''
                )}
                <div className="CustomerExpRow">
                  <div className="CustomerExpDetails">
                    <p>{CustomerExperienceProp.para}</p>
                  </div>
                  <div className="CustomerExpImages">
                    {CustomerExperienceProp.imageLink ? (
                      <video muted loop playsInline autoPlay>
                        <source
                          src={CustomerExperienceProp.imageLink}
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      <figure>
                        <Image
                          fluid={props.fluid[i]}
                          lazyload={{
                            enabled: true,
                            once: true,
                            offset: 1000,
                            heigth: 631,
                          }}
                        />
                        {CustomerExperienceProp.imgCaption ? (
                          <div className="img-caption">
                            {CustomerExperienceProp.imgCaption}
                          </div>
                        ) : (
                          ''
                        )}
                      </figure>
                    )}
                  </div>
                </div>
              </div>
            )
          }
        )}
      </div>
    </CustomerexperiencesecStyles>
  )
}

export default Customerexperiencesec
