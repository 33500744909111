import React from 'react'
import { AutomatingsecStyles } from './Automatingsec.styles'
import Image from 'components/ImgOptimized'

function Automatingsec(props) {
  const {
    Automatingsecdata: { AutomatingsecProps },
    showFullimages,
  } = props

  const {
    data: {
      deploymentAutomationImage: {
        childImageSharp: { fluid },
      },
    },
  } = props
  return (
    <AutomatingsecStyles className="automatingSec">
      <div className="container">
        <div className="AutomatingContentSec">
          <div className="AutomatingContentRow">
            <div className="AutomatingContentLeft">
              <div className="AutomatingContentTitle">
                <h2>{AutomatingsecProps.Title}</h2>
              </div>
            </div>
            <div className="AutomatingDetailsRight">
              <div className="AutomatingDetails">
                <p>{AutomatingsecProps.para}</p>
              </div>
            </div>
          </div>
          {showFullimages && (
            <div className="deploymentAutomationImage">
              <figure>
                <Image
                  fluid={fluid}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 694,
                  }}
                />
                <div className="img-caption">
                  {AutomatingsecProps.imgCaption}
                </div>
              </figure>
            </div>
          )}
        </div>
      </div>
    </AutomatingsecStyles>
  )
}

export default Automatingsec
