import React from 'react'

import { EventscreensecStyles } from './Eventscreensec.styles'
import Image from 'components/ImgOptimized'

function Eventscreensec(props) {
  const {
    eventScrrensecdata: { eventScreensecProps },
  } = props

  return (
    <EventscreensecStyles>
      <div className="eventScrrenSec">
        <div className="container">
          {eventScreensecProps.eventScreenDetails.map((eventScreenProp, i) => {
            return (
              <div className="eventScrrenRow" key={i}>
                <div className="left-part">
                  {eventScreenProp.bottomTitle ||
                  eventScreenProp.bottomContent ? (
                    <div className="bottom-content">
                      <h4>{eventScreenProp.bottomTitle}</h4>
                      <p>{eventScreenProp.bottomContent}</p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="right-part">
                  <div className="event-images">
                    {eventScreenProp.eventScreenTitle.map(
                      (eventScreenTextProp, j) => {
                        return (
                          <div className="img-blk" key={`${i}${j}`}>
                            <Image
                              fluid={props.items[i][j]}
                              lazyload={{
                                enabled: true,
                                once: true,
                                offset: 1000,
                                heigth: 593,
                              }}
                            />
                            <p>{eventScreenTextProp.eventScreenInfo}</p>
                          </div>
                        )
                      }
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </EventscreensecStyles>
  )
}

export default Eventscreensec
