import styled from 'styled-components'
import { bodyFont, md, xl, lg, sm } from 'config/variables'

export const EventscreensecStyles = styled.div`
  padding-bottom: 41px;
  position: relative;
  margin-bottom: 126px;
  ${xl(`
      margin-bottom: 100px;
  `)}
  ${lg(`
    margin-bottom: 90px;
    padding-bottom: 40px;
  `)}
  ${md(`
       margin-bottom: 40px;
       padding-bottom: 20px;
  `)} 
   &:after {
    position: absolute;
    content: '';
    background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f7f7f7);
    width: 100%;
    height: 676px;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
.eventScrrenRow {
    display: flex;
    justify-content: space-between;
    margin-bottom:20px;
    &:last-child {
      margin-bottom:0px;  
    }
    ${md(`
        flex-direction: column;
    `)}
    .left-part {
      max-width: 200px;
      width: 100%;
      display: flex;
      flex-direction: column;
      ${xl(`
        padding-right: 40px;
      `)}
      ${lg(`
        max-width: 152px;
        padding-right: 27px;
      `)}
      ${md(`
        max-width:100%;
      `)}
      .bottom-content {
        position: relative;
        padding-top: 21px;
        margin-bottom: 42px;
        ${md(`
          margin-bottom: 18px;
       `)}
        &:before {
          content: '';
          position: absolute;
          width: 55px;
          height: 3px;
          background: #3d3d3d;
          top: 0;
          left: 0;
        }
        h4 {
          font-size: 15px;
          font-weight: 600;
          font-family: ${bodyFont};
          margin-bottom: 0;
          text-transform: uppercase;
          line-height: 21px;
          letter-spacing: -0.16px;
        }
        p {
          font-size: 15px;
          letter-spacing: -0.16px;
          line-height: 21px;
          margin: 0;
        }
      }
    }
    .right-part {
      max-width: 867px;
      width: 100%;
      ${md(`
         overflow:auto;
        `)}
      .event-images {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: calc(100% + 60px);
        margin-left: -30px;
        ${xl(`
           width:100%;
           margin-left: 0;
        `)}
        ${lg(`
           width:calc(100% + 30px);
           margin-left:-15px;
          `)}
        ${md(`
            width:calc(100% + 30px);
            margin-left:-15px;
            min-width:589px;
            
          `)}
        .img-blk {
          max-width: 309px;
          width: 100%;
          text-align:center;
          margin-bottom:54px;
          ${xl(`
            max-width: 209px;
          `)}
          ${lg(`
            width:33.33%;
            max-width:100%;
            padding:0 15px;
            margin-bottom:10px;
          `)}
          ${md(`
            width:33.33%;
            max-width:100%;
            padding:0 15px;
            margin-bottom:10px;
          `)}
          
          p {
            line-height:30px;
            font-size: 20px;
            margin:0px;
            letter-spacing: -0.14px;
            color:#3d3d3d;
            min-height:60px;
            margin-top:31px;
            ${lg(`
              line-height:30px;
              font-size: 16px;
              margin-top:22px;
              letter-spacing: -0.11px;
            `)}
          }
        }
      }
    }
  }
`
